<template>
  <div style="width: 100%;">
    <loader v-if="!pageLoaded"/>
    <div v-else style="width: 100%;">

      <transition name="modal" v-if="isVideoModal">
        <div  @click="isVideoModal = false" class="modal-mask"  style="overflow-y: scroll; padding-top: 100px; background-color: rgba(0, 0, 0, .8)!important; ">
          <div class="modal-container" style="width: 900px !important; padding:0!important;; max-height: 506px">

            <div >
              <iframe
                allowfullscreen="true"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                width="900" height="506"
                frameborder="0"
                scrolling="no"
                :src="'https://api.bionluk.com/general/video/' +  '10727531'+'x'+ '10727531' +'/custom_video/'"
              >
              </iframe>
            </div>

          </div>
        </div>
      </transition>

      <div v-if="items.length === 0 && status === null" class="no-area">
        <img class="img" src="https://gcdn.bionluk.com/site/cicons/il-first-gig.png">
        <p class="title">İlk <span>İlanını Oluştur</span></p>
        <p class="text">Sevgili <span>{{this.user.firstname ? this.user.firstname : this.user.username}}</span>, yeteneklerini sergilemek için etkileyici bir iş ilanı oluştur. Bionluk da sana iş bulsun!</p>

        <p class="text" style="margin-top: 20px">Nasıl iş ilanı oluşturacağını merak mı ediyorsun? Hadi gel kısa bir video ile nasıl iş ilanı oluşturacağına <span @click="openVideoModal()" class="hover-gg">bi'göz atalım!</span></p>
        <button @click="pushCreate" class="cuper-red-button" style="margin-top: 40px;">İş İlanı Oluştur</button>
      </div>

      <div v-else class="gig-list">

        <div style=" position: relative;  margin-top: 30px;">
          <button style="position: absolute; right: 0; width:185px; font-size: 16px; letter-spacing: -0.3px; font-weight: 600; min-width: 120px!important; padding-right: 25px; padding-left:25px;"
                  class="cuper-red-button"
                  @click="$router.push('/panel/ilanlar/yeni')">
            Yeni İş İlanı Oluştur
          </button>
        </div>

        <p class="page-title">İş <span>İlanlarım</span></p>
        <p class="page-sub-title">Tüm iş ilanlarını buradan takip edebilir, yönetebilir ve yeni iş ilanları oluşturabilirsin.</p>




        <div style="margin-top: 50px; display: flex; justify-content: space-between">
          <select v-model="status" name="status" class="cuper-drop-slim">
            <option :value="null">Tüm ilanlarımı göster</option>
            <option :value="1">Yayındaki ilanlarımı göster</option>
            <option :value="2">Yayında olmayanları göster</option>
            <option :value="-2">Onaylanmayanları göster</option>
            <option :value="0">Yarım kalan ilanlarımı göster</option>

          </select>
          <div style="display: flex; align-items: center">
            <select v-model="allStatus" name="allStatus" class="cuper-drop-slim" style=" width: 180px; background-position-x: 155px;">
              <option :value="null">Aktif / Pasif</option>
              <option :value="1">Hepsini aktif yap</option>
              <option :value="-1">Hepsini pasif yap</option>
            </select>
          </div>
        </div>


        <div style="margin-top: 50px; margin-left: -29px">
          <div class="cardBoxFlexC">


            <div v-for="(item, ke) in items" class="gigBox" style="background-color: white" :key="ke">
              <div v-if="rerender" class="optionsArea">
                <div
                  v-if="item.status === 1" @click="activateUseBicoinModal(item)"
                  data-balloon="İlanı Öne Çıkar"
                  data-balloon-pos="up"
                  class="icons">
                  <img class="status-icon bicoin-i" src="https://gcdn.bionluk.com/site/cicons/ic-bicoinn.svg" onload="SVGInject(this)">
                </div>
                <div
                  v-if="item.status === 1" @click="changeGigStatus(item, 2)"
                  data-balloon="İlanı Yayından Kaldır"
                  data-balloon-pos="up"
                  class="icons">
                  <img class="status-icon-6 remove-i" src="https://gcdn.bionluk.com/site/cicons/ic-eye-close.svg" onload="SVGInject(this)">
                </div>
                <div
                  v-if="item.status === 2" @click="changeGigStatus(item, 1)"
                  data-balloon="Tekrar Yayına Al"
                  data-balloon-pos="up"
                  class="icons">
                  <img class="status-icon-2 active-i" src="https://gcdn.bionluk.com/site/cicons/ic-eye-open.svg" onload="SVGInject(this)">
                </div>
                <div
                  @click="changeGigStatus(item, -1)"
                  data-balloon="İlanı Sil"
                  data-balloon-pos="up"
                  class="icons">
                  <img class="status-icon-3 delete-i" src="https://gcdn.bionluk.com/site/cicons/ic-trash.svg" onload="SVGInject(this)">
                </div>
                <div
                  @click="$router.push(`/panel/ilanlar/duzenle/${item.uuid}`)"
                  data-balloon="Düzenle"
                  data-balloon-pos="up"
                  class="icons">
                  <img class="status-icon-4 edit-i" src="https://gcdn.bionluk.com/site/cicons/ic-edit-penci.svg" onload="SVGInject(this)">
                </div>
              </div>
              <div :class="'statusArea ' + item.statusTextClass">
                {{item.statusText}}
              </div>
              <div v-if="item.commentCount" class="starArea">
                <img style="width: 14px; height: 14px;" src="https://gcdn.bionluk.com/site/icon/icon_tansel_star_full.svg">
                <p class="rate">{{item.commentRating}}</p>
                <p class="count">({{item.commentCount}})</p>
              </div>
              <router-link
                :to="item.slug">
                <div v-if="item.portfolios[0]" class="bg" :style="'background-image: url('+item.portfolios[0].image_url_small+')'">
                  <div v-if="item.portfolios[0].fileType === 'audio'" class="sound-icon-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-sound.svg" onload="SVGInject(this)" class="sound-icon">
                  </div>
                  <div v-if="item.portfolios[0].fileType === 'video'" class="sound-icon-container">
                    <img src="https://gcdn.bionluk.com/site/cicons/ic-video.svg" onload="SVGInject(this)" class="sound-icon">
                  </div>
                </div>
              </router-link>

              <div style="width: 100%;">
                <div style="padding-top: 16px; padding-bottom: 12px; display: flex; align-items: center; height: 40px;">
                  <div style="width: 40px; padding-left: 16px;">
                    <router-link
                      :to="'/'+item.sellerUsername">
                      <img class="avatar" :src="item.sellerAvatarURL">
                    </router-link>
                  </div>
                  <div style="margin-left: 7px; width: 100%; display: flex; height: 40px;  justify-content: space-between">
                    <div style="height: 40px;">
                      <p class="username"><router-link
                        :to="'/'+item.sellerUsername">{{item.sellerUsername}}</router-link></p>
                      <p class="title">{{item.sellerTitle}}</p>
                    </div>
                    <div style="height: 40px; padding-right: 16px;">
                      <p class="price">{{item.price}}<span>TL</span></p>
                      <p class="helpText">{{item.priceHelpText}}</p>
                    </div>
                  </div>
                </div>
              </div>

              <router-link
                class="gigTitleLink" :to="item.slug">
                <p class="gigTitle">Ben, {{item.title}}</p>
              </router-link>
            </div>


          </div>
        </div>



        <div v-if="seemorediv && !showLoader" @click="retrieveGigs(status)"
             style="margin-top:20px;font-size: 18px; text-align: center; color: #2d3640;">
          <a>Daha fazla +</a>
        </div>
        <loader v-if="showLoader"></loader>


      </div>

      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>
      <div class="hiddendiv"></div>
    </div>

  </div>
</template>

<script>

  import dropdown from 'vue-my-dropdown';

  export default {
    name: "src-pages-body-workstation-gigs-list-v1",
    components: {
      dropdown
    },
    data() {
      return {
        isVideoModal:false,
        rerender:true,
        pageLoaded: null,
        showLoader: null,
        visible: false,
        position: ["right", "top", "right", "top"],
        animation: 'ani-slide-y',
        gigs: [],
        offset: 0,
        seemorediv: null,
        status: null,
        totalCount: null,
        allStatus: null
      }
    },
    methods: {

      openVideoModal(){
        this.isVideoModal = true;
        this.Helper.trackEvents.customEvent("custom_video_view", {title:'yeni_ilan', page:this.$store.state.componentMap.page});
      },

      pushCreate(){

        if(!this.user.seller.isRegistered){
          let info = {
            title:'IBAN Bilgisi',
            bodyText:'Banka hesap bilgilerinde eksiklik var. Sana ödeme yapılabilmesi için lütfen "Banka ve Ödeme Bilgileri" sayfasında ödeme bilgilerini güncelle.',
            actionButton:{
              show:true,
              title:'Ayarlar',
              url:'/settings/finance',
              cancelButtonTitle:'İptal'
            }
          };
          this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.WARNING_MODAL, info: info});
        } else {
          this.$router.push('/panel/ilanlar/yeni');
        }
      },

      activateUseBicoinModal(gig) {
        this.$store.commit(this.types.ACTIVE_MODAL, {modalType: this.Modals.USE_BICOIN, info: {uuid: gig.uuid}});
      },

      retrieveGigs(status) {
        this.showLoader = true;
        this.api.seller.retrieveGigs(null, 15, this.offset, status, null)
          .then(({data}) => {
            let result = data;
            this.showLoader = false;

            if (result.success) {
              this.pageLoaded = true;
              result.data.gigs.forEach(function (gig) {
                gig.visible = false;
              });
              this.gigs = this.gigs.concat(result.data.gigs);
              this.offset += 15;
              this.seemorediv = result.data.seemorediv;
              this.totalCount = result.data.totalCount;
            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      },

      changeGigStatus(receivedGig, status) {
        if (status === -1) {
          if (confirm(this.Constants.TEXTS.GIGS_DELETE_WARNING_DESC) === true){
            this.changeGigStatusApiCaller(receivedGig, status); //method ismi değiştirilebilir.
          }
          receivedGig.visible = false;
        }else {
          this.changeGigStatusApiCaller(receivedGig, status); //method ismi değiştirilebilir.
        }
      },

      changeGigStatusApiCaller(receivedGig, status) {
        if (status === -1) {
          this.gigs = this.gigs.filter(function (gig) {
            return gig.uuid !== receivedGig.uuid;
          });
          this.$toasted.global.errorToast({description: "İlanın silindi"});
        }
        this.api.seller.changeGigStatus(receivedGig.uuid, status, this.$Progress)
          .then(({data}) => {

            let result = data;
            if (result.success) {
              this.rerender = false;
              receivedGig.visible = false;
              if (status === -1) {

              } else {
                this.gigs.forEach(function (gig) {
                  if (gig.uuid === receivedGig.uuid) {
                    gig.status = parseInt(result.data.new_status);
                    gig.statusText = result.data.new_statusText;
                    gig.statusTextClass = result.data.new_statusTextClass;
                  }
                });

                if (this.status !== null) {
                  this.gigs = this.gigs.filter(gig => {
                    return this.status === gig.status;
                  })
                }
              }
              this.$nextTick(() => {
                this.rerender = true;
              });

            } else {
              this.$toasted.global.errorToast({description: result.message});
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      } ,

      changeAllGigStatus() {

          if(this.allStatus){
              this.api.seller.changeAllGigStatus(this.allStatus, this.status, this.$Progress)
                  .then(({data}) => {
                      let result = data;
                      if (result.success) {
                        this.offset = 0;
                        this.api.seller.retrieveGigs(null, 15, this.offset, this.status, null)
                          .then(({data}) => {
                            let result = data;

                            if (result.success) {
                              this.pageLoaded = true;
                              result.data.gigs.forEach(function (gig) {
                                gig.visible = false;
                              });
                              this.gigs = result.data.gigs;
                              this.seemorediv = result.data.seemorediv;
                              this.totalCount = result.data.totalCount;
                              this.offset += 15;
                            } else {
                              this.$router.push(result.data.redirect_url);
                            }
                          })
                          .catch(err => {
                            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
                          });                          this.$toasted.global.infoToast({description: result.message});
	                      this.allStatus = null;
                      } else {
                          this.$toasted.global.errorToast({description: result.message});
	                      this.allStatus = null;
                      }
                  })
                  .catch(err => {
                      this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
	                  this.allStatus = null;
                  });
          }

      }

    },
    computed:{

      items: function () {
        return JSON.parse(JSON.stringify(this.gigs));
      }
    },
    watch: {
      status(newVal, oldVal) {
        this.pageLoaded = false;
        this.offset = 0;
        this.gigs = [];
        this.retrieveGigs(newVal);
      },

      allStatus(newVal, oldVal) {
        this.changeAllGigStatus();
      },

      "$store.state.windowScrollTop": function (newValue, oldValue) {
        if(oldValue && newValue){
          this.$store.state.gigScrollTopPosition = newValue;
        }
      }
    },
    created() {
      this.$nextTick(() => {
        this.api.seller.retrieveGigs(null, 15, this.offset, this.status, null)
          .then(({data}) => {
            let result = data;

            if (result.success) {
              this.pageLoaded = true;
              result.data.gigs.forEach(function (gig) {
                gig.visible = false;
              });
              this.gigs = result.data.gigs;
              this.offset += 15;
              this.seemorediv = result.data.seemorediv;
              this.totalCount = result.data.totalCount;
              this.$nextTick(() => {
                window.scrollTo(0,this.$store.state.gigScrollTopPosition);
              });
            } else {
              this.$router.push(result.data.redirect_url);
            }
          })
          .catch(err => {
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      });
    }
  }

</script>

<style scoped lang="scss">


.hover-gg{
  color: #2cad87!important;
  font-weight: 600;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
  .cardBoxFlexC{

    display: flex;
    flex-wrap: wrap;

  }


  .gigBox{


    position: relative;
    display: flex;



    flex-direction: column;
    align-items: center;
    margin-left: 28px;

    width: 280px;
    height: 316px;
    margin-bottom: 50px;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    background-color: #ffffff;


    .bg{

      position:relative;width:280px; border-top-left-radius: 10px; border-top-right-radius: 10px; height:158px; background-size: contain; background-repeat: no-repeat;
    }


    .optionsArea{
      z-index: 33;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 0;

      border-bottom-left-radius: 12px;
      border-top-right-radius: 12px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #2d3640;


      .icons{
        cursor: pointer;
        height: 40px;
       width: 41px;
        display: flex;
        align-items: center;
        justify-content: center;
        .status-icon /deep/{
          width: 18px;
          height: 18px;
          path {
            fill: #fff;
          }
        }

        .status-icon-2 /deep/{
          width: 18px;
          height: 18px;
          path {
            fill: #fff;
          }
        }

        .status-icon-3 /deep/{
          width: 18px;
          height: 18px;
          path {
            fill: #fff;
          }
        }

        .status-icon-4 /deep/{
          width: 18px;
          height: 18px;
          path {
            fill: #fff;
          }
        }

        .status-icon-5 /deep/{
          width: 18px;
          height: 18px;
          path {
            fill: #fff;
          }
        }

        .status-icon-6 /deep/{
          width: 18px;
          height: 18px;
          path {
            fill: #fff;
          }
        }

        &:hover{
          opacity: 0.8;
        }

      }

    }
    .starArea{
      z-index: 33;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      right: 0;
      top: 113px;
      width: 100px;
      height: 30px;
      border-bottom-left-radius: 15px;
      border-top-left-radius: 15px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      background-color: #ffffff;

      img{

        width: 16px;
        height: 16px;
      }

      .rate{

        margin-left: 3px;
        font-size: 12px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffbe00;

      }

      .count{

        margin-left: 2px;
        font-size: 12px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #2d3640;
      }
    }


    .statusArea {

      z-index: 33;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 113px;
      padding-left: 15px;
      padding-right: 15px;
      padding-bottom: 3px;
      height: 27px;
      border-top-right-radius: 15px;
      border-bottom-right-radius: 15px;

      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
    }
    .green {
      background: #6dce6a;
    }

    .gray {
      background: #8b95a1;
      box-shadow: 0 0 10px 0 #00000019;
    }

    .yellow {
      background: #f29933;
    }

    .red {
      background: #fd4056;
      box-shadow: 0 0 10px 0 #00000019;
    }


    .likeAreaWhite{
      cursor: pointer;
      position: absolute;
      z-index: 33;
      right: 0;
      width: 40px;
      height: 40px;
      background-color: #2d3640;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .likeIconWhite /deep/ {
        width: 18px;
        height: 18px;
        path {
          fill: #fff;
        }
      }
      &:hover{
        .likeIconWhite /deep/ {
          width: 18px;
          height: 18px;
          path {
            fill: #fd4056;
          }
        }
      }


    }
    .likeAreaRed{
      cursor: pointer;
      position: absolute;
      z-index: 1;
      right: 0;
      width: 40px;
      height: 40px;
      background-color: #fd4056;
      border-top-right-radius: 10px;
      border-bottom-left-radius: 10px;
      display: flex;
      align-items: center;
      justify-content: center;

      .likeIconRed /deep/ {
        width: 18px;
        height: 18px;
        path {
          fill: #fff;
        }
      }
    }
    .avatar{
      width: 38px;
      height: 38px;
      border-radius: 50%;
      border: 1px solid #eaeaea;
    }
    .username{
      font-size: 14px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.2;
      letter-spacing: normal;
      color: #2d3640;
    }

    .title{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 130px;
      font-size: 13px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.8;
      letter-spacing: normal;
      color: #8b95a1;
    }
    .price{
      font-size: 26px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;

      letter-spacing: -1.5px;
      margin-top: -8px;
      text-align: right;
      color: #26ca9a;
      span{
        font-size: 18px;
        font-weight: 500;
        font-style: normal;
        font-stretch: normal;

        letter-spacing: normal;
        text-align: center;
        color: #26ca9a;
        padding-left: 3px;
      }
    }

    .helpText{
      font-size: 11px;
      font-weight: 300;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: right;
      color: #8b95a1;
    }

    .gigTitle{
      width: 250px;
      margin: 0 auto;
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: 1.5;
      letter-spacing: normal;
      color: #2d3640;
    }

    .gigTitleLink{
      &:hover{
        text-decoration: none!important;
      }
    }
  }

  .gig-list{

    margin-left: 57px;
    width: 895px;
    .page-title{
      font-size: 34px;
      font-weight: 400;
      color: #2d3640;
      span{
        font-weight: 600;
      }
    }

    .page-sub-title{
      font-size: 18px;
      margin-top: 10px;
      line-height: 1.44;
      letter-spacing: normal;
      color: #6a7685;

    }



  }


  .no-area{

    margin: 60px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    .img{
      width: 335px;
      height: 183px;
    }

    .title{
      margin-top: 50px;
      margin-bottom: 16px;
      font-size: 34px;
      font-weight: normal;
      text-align: center;
      color: #2d3640;
      span{
        font-weight: bold;
      }
    }
    .text{
      max-width: 543px;
      font-size: 18px;
      line-height: 1.44;
      text-align: center;
      color: #5e6b79;
      span{
        font-weight: bold;
        color: #2d3640;
      }
    }
  }









  .chatrow {
    height: 80px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    border-top: solid 1px #eaedf2;
  }

  .content-ustband {
    padding: 0;
    display: flex;
    align-content: center;
    align-items: center;
    padding-left: 20px;
    padding-right: 20px;
    border-bottom: 1px solid #eaedf2;
    width: 780px;
  }

  .filter-title {
    font-size: 14px;
    color: #8b95a1;
    margin-right: 50px;
  }

  .filter-inner {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .super-drop-tek-cizgi {
    -webkit-appearance: none;
    background: url(https://gcdn.bionluk.com/site/icon/aws/icon_alt_ok_1.svg) no-repeat right;
    background-position-x: 140px;
    -moz-appearance: none;
    min-width: 160px;
    appearance: none;
    border-radius: 2px;
    font-family: 'sofia-pro';
    position: relative;
    background-color: #fefdfd;
    color: #939c9c;

    border: none;
    border-bottom: solid 1px #bfc8d2;

    font-size: 14px;
    padding: 4px 5px 6px;
  }

  .super-drop-tek-cizgi:hover {
    border: none;
    border-bottom: solid 1px #2d3640;
    color: #2d3640;
    cursor: pointer;
  }

  .menu-list {
    line-height: 1.25;
  }

  ul {
    list-style: none;
  }

  li {
    display: list-item;
    text-align: -webkit-match-parent;
  }

  li:hover {
    display: list-item;
    text-align: -webkit-match-parent;
    color: #2d3640;
  }

  .menu-list li {
    cursor: pointer;
    border-radius: 2px;
    color: #4a4a4a;
    display: block;
    padding: .5em .75em;
  }

  .menu-list li:hover {
    border-radius: 2px;
    color: #2d3640;
    display: block;
    padding: .5em .75em;
    text-decoration: none;
  }
</style>
